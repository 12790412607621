var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"width":"1200px","persistent":""}},[_c('v-card',{staticClass:"px-2 pb-2 pt-4"},[_c('v-card-text',{staticClass:"px-3 pt-4"},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('h2',[_vm._v("Configuração do downsell")])])],1),(_vm.productsList.length)?_c('v-data-table',{key:"index",attrs:{"footer-props":{ itemsPerPageOptions: [5, 10] },"headers":_vm.headers,"items":_vm.productsList},scopedSlots:_vm._u([{key:"item.product_type",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getProductFromType(item.product_type).name)+" ")])],1)]}},{key:"item.total_mrr",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.parseCurrencyDefault(item.total_mrr))+" ")])],1)]}},{key:"item.slots",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.slots)+" ")])],1)]}},{key:"item.slotsToRemove",fn:function(ref){
var item = ref.item;
return [(_vm.getProductFromType(item.product_type).hasUnits)?_c('v-autocomplete',{attrs:{"rules":[_vm.notEmptyRule],"items":_vm.getClientUnitsWithSelectAll(item.client_units),"dense":"","multiple":"","label":"Lojas","placeholder":"Buscar","item-text":"displayName","return-object":"","hide-details":"","outlined":""},on:{"change":function (selected) { return _vm.handleSelectUnit(item, selected); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var unitItem = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(item.selectedUnits.filter(function (unit) { return !unit.value.all; }).length)+" selecionada(s)")]):_vm._e()]}}],null,true),model:{value:(item.selectedUnits),callback:function ($$v) {_vm.$set(item, "selectedUnits", $$v)},expression:"item.selectedUnits"}}):_c('field-toggle',{model:{value:(item.slotsToRemove),callback:function ($$v) {_vm.$set(item, "slotsToRemove", $$v)},expression:"item.slotsToRemove"}})]}}],null,true)}):_vm._e(),_c('v-divider',{staticClass:"mx-2"})],1),_c('v-card-actions',{staticClass:"my-2"},[_c('v-spacer'),_c('mf-action-buttons',{staticClass:"mr-1",attrs:{"primary-button":{
          text: 'Salvar',
          action: _vm.saveDownsell,
          isVisible: true,
          isDisabled: _vm.isQuantityValid
        },"extra-button":{
          text: 'Cancelar',
          action: _vm.close,
          isVisible: true
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }