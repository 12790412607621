<template>
  <v-dialog :value="dialog" width="1200px" persistent>
    <v-card class="px-2 pb-2 pt-4">
      <v-card-text class="px-3 pt-4">
        <v-row align="center" no-gutters>
          <v-col cols="4">
            <h2>Configuração do downsell</h2>
          </v-col>
        </v-row>
        <v-data-table v-if="productsList.length" key="index" :footer-props="{ itemsPerPageOptions: [5, 10] }" :headers="headers" :items="productsList">
          <template v-slot:[`item.product_type`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ getProductFromType(item.product_type).name }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.total_mrr`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseCurrencyDefault(item.total_mrr) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.slots`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.slots }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.slotsToRemove`]="{ item }">
            <v-autocomplete
              v-if="getProductFromType(item.product_type).hasUnits"
              v-model="item.selectedUnits"
              :rules="[notEmptyRule]"
              :items="getClientUnitsWithSelectAll(item.client_units)"
              dense
              multiple
              label="Lojas"
              placeholder="Buscar"
              item-text="displayName"
              return-object
              hide-details
              outlined
              @change="selected => handleSelectUnit(item, selected)"
            >
              <template v-slot:selection="{ item: unitItem, index }">
                <span v-if="index === 0">{{ item.selectedUnits.filter(unit => !unit.value.all).length }} selecionada(s)</span>
              </template>
            </v-autocomplete>
            <field-toggle v-else v-model="item.slotsToRemove"></field-toggle>
          </template>
        </v-data-table>
        <v-divider class="mx-2" />
      </v-card-text>
      <v-card-actions class="my-2">
        <v-spacer />
        <mf-action-buttons
          class="mr-1"
          :primary-button="{
            text: 'Salvar',
            action: saveDownsell,
            isVisible: true,
            isDisabled: isQuantityValid
          }"
          :extra-button="{
            text: 'Cancelar',
            action: close,
            isVisible: true
          }"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { DateTime } from 'luxon'
import { allowedDateCrmActions } from '../../../helpers/crm'
import { parseCurrency, productsInformation } from '@/mixin'
import cnpj from '@/filters/cnpj'

export default {
  components: {
    FieldToggle: () => import('@/components/atomic-components/atoms/FieldToggle.vue')
  },
  mixins: [parseCurrency, productsInformation],
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    products: {
      type: Array,
      default: () => []
    },
    clientId: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      productsList: [],
      disabled: false,
      date_down_sell: DateTime.now().toISODate(),
      notEmptyRule: v => v.length >= 1 || 'Campo obrigatório',
      contract_link: '',
      observation: ''
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID Produto',
          value: '_id',
          align: 'center'
        },
        {
          text: 'Produto',
          value: 'product_type',
          align: 'center'
        },
        {
          text: 'Total MRR',
          value: 'total_mrr',
          align: 'center'
        },
        {
          text: 'Quantidade Atual',
          value: 'slots',
          align: 'center'
        },
        {
          text: 'Quantidade a ser removida',
          value: 'slotsToRemove',
          align: 'center',
          width: '25%'
        }
      ]
    },
    isQuantityValid() {
      const invalid = this.products.filter((product, index) => {
        return product?.slots < this.productsList?.[index]?.slots || this.productsList?.[index]?.slots <= 0 || this.disabled
      })
      return !!invalid.length
    }
  },
  mounted() {
    if (this.products.length) {
      this.productsList = cloneDeep(this.products).map(item => ({
        ...item,
        slotsToRemove: 0,
        selectedUnits: [],
        isAllSelected: false
      }))
    }
  },
  methods: {
    cnpj,
    handleSelectUnit(item, selectedUnits) {
      if (selectedUnits.some(selected => selected?.value?.all)) {
        if (!item.isAllSelected) {
          item.isAllSelected = true
          item.selectedUnits = this.getClientUnitsWithSelectAll(item.client_units)
        } else {
          item.isAllSelected = false
          item.selectedUnits = selectedUnits.filter(selected => !selected?.value?.all)
        }
      } else if (item.isAllSelected) {
        item.selectedUnits = []
        item.isAllSelected = false
      }
    },
    getClientUnitsWithSelectAll(items) {
      return [
        {
          displayName: `Todas (${items.length})`,
          value: {
            all: true
          }
        }
      ].concat(
        items.map(item => ({
          displayName: `${cnpj(item.cnpj)} | ${item.name}`,
          value: {
            all: false,
            _id: item._id
          }
        }))
      )
    },
    /**
     * Dates allowed in calendar display
     * @param {*} val
     * @return {Bollean}
     */
    allowedDates(val) {
      return allowedDateCrmActions(val)
    },
    close() {
      this.$emit('update:dialog', false)
    },
    async saveDownsell() {
      const variables = {
        client_id: this.clientId,
        sale: {
          type: 'downsell',
          items: this.productsList.map(item => {
            return {
              available_product_id: item._id,
              sale_id: item.sale_id,
              quantity: item.selectedUnits?.length ? item.selectedUnits.filter(unit => !unit.value.all).length : item.slotsToRemove,
              client_unit_ids: item.selectedUnits.filter(unit => !unit.value.all).map(unit => unit.value._id),
              total_value: item.total_mrr,
              unit_value: item.total_mrr / item.slots,
              index: +item.index,
              type: item.product_type
            }
          })
        }
      }
      this.$emit('click', variables)
    },
    getIndex(val) {
      return this.products.indexOf(val)
    }
  }
}
</script>
